.cursorPointer {
	cursor: pointer;
}
.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}
.rbt-menu > .dropdown-item {
	display: block;
	margin: 10px;
	font-size: 16px;
}
.sortIcon {
	font-size: 15px;
}

.active-sort {
	font-size: 14px !important;
	color: black !important;
}
.shareImage {
	width: 100%;
}
.shareImageImg {
	width: 100%;
	min-height: 70vh;
	object-fit: cover;
}
.studntNameShare {
	margin: 20px 0px;
	font-weight: 600;
}
.shareitemStu {
	width: 100%;
	margin: 30px 0px;
}
@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.row {
	margin: 0px;
	position: relative;
}
.p-0 {
	padding: 0px;
}
.card {
	box-shadow: 0 0.3125rem 1rem -0.1875rem rgba(0, 0, 0, 0.2);
	border: 0px;
}

@font-face {
	font-family: SourceSansPro;
	src: url(./assets/fonts/SourceSansPro-Regular.ttf);
}
@font-face {
	font-family: SourceSansProBold;
	src: url(./assets/fonts/SourceSansPro-Bold.ttf);
}
.btn {
	outline: none;
	box-shadow: none;
}
body {
	font-family: SourceSansPro;
}
.d-flex {
	display: flex;
}
.w-100 {
	width: 100%;
}
.h-100 {
	height: 100%;
}

.m-i-s-20 {
	margin-inline-start: 20px;
}
input {
	outline: none;
}
textarea {
	outline: none;
}
.m-inline-40 {
	margin-inline: 40px;
}
.m-b-40 {
	margin-bottom: 40px;
}
.containerPages {
	margin: 30px 0px 100px;
	padding: 0px 40px;
}
@media (max-width: 1000px) {
	.containerPages {
		padding: 0px 20px;
	}
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.align-item-center {
	align-items: center;
}
button:focus {
	outline: none !important;
}
i {
	cursor: pointer;
}
.mtb-50 {
	margin: 50px 0px;
}

button {
	cursor: pointer;
}
/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
/* select {
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
  -webkit-appearance: none;
  background-position-x: 90%;
} */
