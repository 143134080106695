body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@font-face {
	font-family: 'SourceSansPro-Regular';
	src: local('SourceSansPro-Regular'),
		url('./assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Sofiaprofont';
	src: local('Sofiaprofont'),
		url('./assets/fonts/Sofia-Pro-Font/Sofia\ Pro\ Regular\ Az.otf')
			format('truetype');
}

@font-face {
	font-family: 'Sofiaprofont-bold';
	src: local('Sofiaprofont-bold'),
		url('./assets/fonts/Sofia-Pro-Font/Sofia\ Pro\ Bold\ Az.otf')
			format('truetype');
}
/*
@font-face {
  font-family: "Sofiaprofont-italic";
  src: local("Sofiaprofont-italic"),
    url("./assets/fonts/Sofia-Pro-Font/Sofia\ Pro\ Italic\ Az.otf") format("truetype");
}
*/
@font-face {
	font-family: 'Sofiaprofont-semibold';
	src: local('Sofiaprofont-semibold'),
		url('./assets/fonts/Sofia-Pro-Font/Sofia\ Pro\ Semi\ Bold\ Az.otf')
			format('truetype');
}

@font-face {
	font-family: 'Sofiaprofont-light';
	src: local('Sofiaprofont-light'),
		url('./assets/fonts/Sofia-Pro-Font/Sofia\ Pro\ Light\ Az.otf')
			format('truetype');
}

@font-face {
	font-family: 'Sofiaprofont-black';
	src: local('Sofiaprofont-black'),
		url('./assets/fonts/Sofia-Pro-Font/Sofia\ Pro\ Black\ Az.otf')
			format('truetype');
}

@font-face {
	font-family: 'Sofiaprofont-extralight';
	src: local('Sofiaprofont-extralight'),
		url('./assets/fonts/Sofia-Pro-Font/Sofia\ Pro\ ExtraLight\ Az.otf')
			format('truetype');
}

@font-face {
	font-family: 'Sofiaprofont-ultralight';
	src: local('Sofiaprofont-ultralight'),
		url('./assets/fonts/Sofia-Pro-Font/Sofia\ Pro\ UltraLight\ Az.otf')
			format('truetype');
}

@font-face {
	font-family: 'Sofiaprofont-medium';
	src: local('Sofiaprofont-medium'),
		url('./assets/fonts/Sofia-Pro-Font/Sofia\ Pro\ Medium\ Az.otf')
			format('truetype');
}

body {
	font-family: 'Sofiaprofont';
}
.b-15 {
	border-radius: 15px;
}

.b-30 {
	border-radius: 30px;
}

.bgr-green {
	background-color: #37b899;
}
.bgr-blue {
	background-color: #2b3d5f;
}

.dropshadow {
	filter: drop-shadow(2px 0px 5px #88a1c8);
}

.bgr-paleorange {
	background-color: #ffebd9;
}

.bgr-lightwhite {
	background-color: #fafbff;
}

.txt-orange {
	color: #fda14d;
}

.txt-lightcyan {
	color: #88a1c8;
}

.txt-cyan {
	color: #2b3d5f;
}
.txt-sofia {
	font-family: 'Sofiaprofont';
}

.txt-sofiabold {
	font-family: 'Sofiaprofont-bold';
}

.txt-sofiasemibold {
	font-family: 'Sofiaprofont-semibold';
}
.txt-sofiablack {
	font-family: 'Sofiaprofont-black';
}

.txt-sofialight {
	font-family: 'Sofiaprofont-light';
}

.txt-sofiaextralight {
	font-family: 'Sofiaprofont-extralight';
}

i.addbutton {
	background-image: url('/src/assets/icons/Other/Calendar@2x.png');
}
